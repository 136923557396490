.filter---model .form-label{
  font-size : 14px!important;
}

.company_profile img {
  height: 45px;
  width: 45px;
  border-radius: 30px;
}
.profile_details h6 {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
}
.profile_details .Profile_post {
  font-size: 14px;
  color: rgb(58, 57, 57);
  font-weight: 500;
}
.qualification {
  font-size: 16px;
  font-family: Sarala;
  font-weight: 500;
}
.about {
  font-size: 16px;
  font-family: Sarala;
  font-weight: 500;
}

.bottom_area {
  height: 40px;
  width: 100%;
  background-color: #fff;
  line-height: 40px;
}
.read_more {
  width: 70%;
  float: left;
  font-size: 15px;
  text-decoration: none !important;
  color: #000 !important;
  outline: 1px solid #e9e9e9;
}

.read_more:hover {
  background-color: rgb(255, 98, 98);
  color: #fff !important;
}

.sort_list {
  width: 30%;
  float: left;
  font-size: 15px;
  outline: 1px solid #e9e9e9;
}
.sort_list:hover {
  background-color: rgb(255, 98, 98);
  color: #fff;
}

.shortListedActive {
  background-color: rgb(255, 98, 98);
  color: #fff;
}




.exprience-input{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.exprience-input input{
  height: 40px;
  width: 49%;
  outline: none;
  border: 1px solid #dee2e6;
  font-family: Roboto,sans-serif;
  font-size: .75rem;
  font-weight: 400;
  border-radius: 4px;
  padding: 8px 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}


/* user profile */
.side_profile {
  height: auto;
  background-color: #306492;
}
.profile___details {
  background-color: #fff;
}
.user_image img {
  margin-top: 30px;
  height: 90px;
  width: 90px;
  border-radius: 100px;
  border: 2px solid #fff;
  margin-bottom: 10px;
}
.user_image h5 {
  font-size: 16px;
  color: #fff;
}
.user_image h6 {
  font-size: 14px;
  color: #fff;
}
.side_details h6 {
  font-size: 17px;
  color: #fff;
}
.side_details span {
  font-size: 14px;
  color: rgb(236, 236, 236);
}

.side_language h6 {
  font-size: 17px;
  color: #fff;
}
.side_language span {
  font-size: 14px;
  color: rgb(236, 236, 236);
}

.right_profile i {
  color: #ffffff;
  background-color: #386688;
  border-radius: 100%;
  font-size: 15px;
  padding: 2px 4px;
}
.right_profile span {
  font-size: 16px;
  padding-left: 10px;
  font-weight: 700;
  color: #386688;
}

.right_exprience i {
  color: #ffffff;
  background-color: #386688;
  border-radius: 100%;
  font-size: 14px;
  padding: 3px 5px;
}
.right_exprience span {
  font-size: 16px;
  padding-left: 10px;
  font-weight: 700;
  color: #386688;
}
.exprience_details h6 {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 700;
}
.exprience_details span {
  font-size: 13px;
  color: #a7a7a7;
  padding-left: 0px;
  font-weight: 400;
}
.exprience_details p {
  font-size: 14px;
  font-weight: 450;
}

.right_education i {
  color: #ffffff;
  background-color: #386688;
  border-radius: 100%;
  font-size: 14px;
  padding: 3px 5px;
}
.right_education span {
  font-size: 16px;
  padding-left: 10px;
  font-weight: 700;
  color: #386688;
}
.education_details h6 {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 650;
}
.education_details span {
  font-size: 13px;
  color: #a7a7a7;
  padding-left: 0px;
  font-weight: 400;
}
.education_details p {
  margin-top: 2%;
  font-size: 15px;
  font-weight: 450;
  color: rgb(54, 54, 54);
  line-height: 7px;
}

.input-group{
  margin-left: -7px;
}
.location{
  margin-left: -25px!important;
}

@media only screen and (max-width: 600px) {
  .exprience_details p {
    font-size: 14px;
    font-weight: 450;
    line-height: 18px;
  }
  .input-group{
    margin-left: 0px;
  }
  .location{
    margin-top: -5px!important;
    margin-left: 0px!important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .exprience_details p {
    font-size: 14px;
    font-weight: 450;
    line-height: 22px;
  }
}



.card-footer{
  border: none;
}

.profile_card{
  position: relative;
}
.column1 img{
  height: 90px;
  width: 90px;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.column1{
  position: absolute;
  top: -50px;
  left: 0;
 }

.cardFirstRow {
  display: flex;
  flex-wrap: nowrap;
}
.column1 {
  width: 100%;
}
.column2 {
  margin-top: 20px;
  width: 100%;
  padding: 0px 20px;
  text-align: center;
}
.column2 h5 {
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  font-family: Antic Slab;
}

.column2 p{
  font-family: Roboto;
}

.column3,
.column4 {
  width: 70%;
  margin: auto;
}

.column4{
  margin-top: -60px;
}
.column5{
  margin-top: -60px;
}

.cardButtonRow .cardButton1 {
  margin: 0 !important;
  padding: 0 !important;
  outline: none !important;
  border: none !important;
  border-radius: 10px;
}
.cardButtonRow .cardButton2 {
  color: rgb(255, 255, 255);
  margin: 0 !important;
  outline: none !important;
  padding: 6px 20px;
  font-size: 14px;
  border: none !important;
  border-radius: 30px;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(41,96,218,1) 0%, rgba(20,84,194,1) 62%, rgba(41,115,167,1) 100%, rgba(0,212,255,1) 100%);  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}
.cardButtonRow .cardButton1:hover {
  background: tomato;
}
.cardButtonRow .cardButton2:hover {
  background: tomato;
  color: white;
  font-weight: 500;
  border: none !important;
  border-radius: 0px 10px 0px 10px;
  -webkit-border-radius: 0px 10px 0px 10px;
  -moz-border-radius: 0px 10px 0px 10px;
  -ms-border-radius: 0px 10px 0px 10px;
  -o-border-radius: 0px 10px 0px 10px;
}

.cardFirstRow img {
  border-radius: 100px;
  border: 3px solid #fff;
}
.workInfo {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: center;
  line-height: 18px;
}


.exploreProfileCard .card {
  min-height: 290px;
  position: relative;
}

.exploreProfileCard .card .digitalCVLink{
  position: absolute;
  bottom: 7px;
  left: 35%;
  z-index: 4444;
}
.exploreProfileCard .card .digitalCVLink a{
  color: goldenrod;
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
}

.skill .skillDesign {
  background-color: #3e9db3;
  color: #fff;
  padding: 5px 9px;
  margin: 2px;
  /*width: auto;*/
}

.cardImage {
  min-height: 180px;
  width: auto;
}

.serach_btn{
  border: none;
  outline: none;
  color: rgb(46, 41, 41);
  padding: 8px 25px;
  font-weight: 500;
  font-size: 14px;
  background-color: #fded10;
}
.reset_btn{
  border: none;
  outline: none;
  color: rgb(255, 251, 251);
  padding: 8px 25px;
  font-size: 14px;
  background-color: #ff645f;
}
.form-control{
  height: 40px;
  width: 100%;
}
.form-check{
  margin-left: 10px;
}

.form-check label{
  font-size: 14px;
  padding-left: 6px;
}

.mobile_search{
  display: none;
}
.mobile_search select{
  width: 100%;
  height: 40px;
  border: 1px solid #e1e1e1;
  outline: none;
  font-size: 14px;
}


@media only screen and (max-width: 600px) {
  .column1{
    position: absolute;
    top: -45px;
   }
   .serach_btn{
     margin-left: 15px;
   }
   .mobile_search{
      display: block;
    }
    .search_bar{
      display: none;
    }
}

@media only screen and (max-width: 768px) {
  .column1{
    position: absolute;
    top: -45px;
   }
   .column4{
    margin-top: -60px;
  }
  .column5{
    margin-top: -60px;
  }
   .cardButtonRow .cardButton2 {
    padding: 6px 13px;
  }
  .mobile_search{
    display: block;
  }
  .search_bar{
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .cardButtonRow .cardButton2 {
    padding: 6px 13px;
  }
}

.digitalCVButton{
  position: absolute;
  top: 3px;
  right: 3px;
}