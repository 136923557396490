/* .jobViewSection{
    height: 80vh;
    overflow-y: scroll;
} */
.jobViewSection .totalCount {
    width: auto;
    text-align: center;
    font-size: 15px;
    margin: 10px 15px 10px 15px;
    color: #1d1c1a;
}

.jobViewSection .totalCount b{
    background-color: powderblue;
    padding: 10px;
}

#jobAlert {
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}

#createJobAlert {
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}

.delete_btn {
    border: none!important;
    outline: none!important;
    font-size: 16px;
    padding: 2px 10px;
    border-radius: 2px;
    color: #fff;
    background-color: rgb(255, 66, 66);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.View_btn {
    font-size: 16px;
    background-color: rgb(52, 128, 243);
    border: none!important;
    outline: none!important;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.job_section .mdi-eye {
    color: #fff;
    border: none!important;
    background-color: rgb(52, 128, 243);
    outline: none!important;
}

.job_detail label {
    font-size: 14px;
    line-height: 6px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.applied label {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poly', serif;
    color: #000;
}

.applied {
    display: flex;
}

.applied span {
    font-size: 14px;
    padding-top: 7px;
    padding-left: 10px;
}

.sactive {
    height: 25px;
    font-size: 13px;
    background-color: rgb(17, 128, 17);
    border: none;
    padding: 0px 20px;
    outline: none;
    margin-left: 10px;
}

.sdeactive {
    height: 25px;
    font-size: 13px;
    background-color: rgb(255, 53, 53);
    border: none;
    padding: 0px 20px;
    outline: none;
    margin-left: 10px;
}

.applied {
    float: right;
}

@media only screen and (max-width: 600px) {
    .delete_btn {
        font-size: 14px;
        padding: 2px 6px;
    }
    .View_btn {
        font-size: 14px;
        padding: 1px 2px;
    }
    .job_detail label {
        line-height: 18px;
    }
    .applied {
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .applied_bottom {
        margin-top: -10px;
        padding-bottom: 10px;
        margin-left: -20px;
    }
}


/* appliedCandidateCard css start */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.appliedCandidateCard {
    position: relative;
}

.appliedCandidateCard .card {
    position: relative;
    width: 100%;
    height: 250px;
    background: #232323;
    border-radius: 20px;
    overflow: hidden;
}

.appliedCandidateCard .card:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3C507E;
    clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;
}

.appliedCandidateCard .card.activeCard:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffaf00;;
    clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;
}

.appliedCandidateCard .card:hover:before {
    clip-path: circle(300px at 80% -20%);
}

.appliedCandidateCard .card .imgBx {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.5s;
    height: 150px;
    width: 150px;
}

.appliedCandidateCard .card:hover .imgBx {
    top: 35%;
}

.appliedCandidateCard .card .imgBx img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate( 0%, -50%) rotate(0);
    height: 150px;
    width: 150px;
    border-radius: 100%;
}

.appliedCandidateCard .card .contentBx {
    position: absolute;
    bottom: -5%;
    width: 100%;
    height: 50px;
    text-align: center;
    transition: 1s;
    z-index: 10;
    background: #232323;
    padding-top: 10px;
}

.appliedCandidateCard .card:hover .contentBx {
    height: 170px;
    margin-top: 100px;
}

.appliedCandidateCard .card:hover .contentBx .size {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.5s;
}

.appliedCandidateCard .card:hover .contentBx .color {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.6s;
}

.appliedCandidateCard .card .contentBx .size h3,
.appliedCandidateCard .card .contentBx .color h3 {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 2px;
    margin-right: 10px;
}

.appliedCandidateCard .infoButton1{
    margin: 0!important;
    padding: 0!important;
}

@media (min-width: 769px) and (max-width: 1024px) {
    .appliedCandidateCard .card .imgBx img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-25%, -50%) rotate(0);
        height: 150px;
        width: 150px;
        border-radius: 100%;
    }
}

@media (min-width: 376px) and (max-width: 768px) {
    .appliedCandidateCard .card .imgBx img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -20%, -50%) rotate(0);
        height: 150px;
        width: 150px;
        border-radius: 100%;
    }
}

@media only screen and (max-width: 375px) {
    .appliedCandidateCard .card .imgBx img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( 0%, -50%) rotate(0);
        height: 150px;
        width: 150px;
        border-radius: 100%;
    }
}


/* appliedCandidateCard css start */



.snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
}

.snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
}
  
@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}