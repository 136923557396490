.coupan{
    height: auto;
    width: 100%;
    border: 1px solid #e1e1e1;
    background-image: url('./coupan2.jpg');
    background-repeat: no-repeat;
    background-size: cover;   
}
.coupan-image{
    height: 70px;
    width: 70px;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
.coupan_detail{
    border-bottom: 2px dotted #e1e1e1;
    padding-bottom: 10px;
    padding-left: 20%;
}

.coupan_detail h6{
    font-size: 14px;
    font-family: sans-serif;
    color: #ffffff;
}
.coupan_detail span{
    font-weight: 700;
    color: #286e07;
}

.coupan_detail h5{
    font-size: 15px;
    color: #ffffff;
    font-family: sans-serif;
}

.coupan_detail h3{
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    font-family: sans-serif;
}


.coupan_code p{
    font-size: 16px;
    font-family: monospace;
    font-weight: 500;
    color: #ffffff;
    line-height: 10px;
    
}
.coupan_code h2{
    line-height: 18px;
    font-size: 22px;
    color: #ffffff;
}

.coupan_info select{
    width: 100%;
    height: 35px;
    outline: none;
    font-size: 14px;
    border: 1px solid #e1e1e1;
}

.coupan_info input{
    width: 100%;
    height: 35px;
    border: 1px solid #e1e1e1;
    outline: none;
    font-size: 14px;
}
.coupan_info label{
    font-size: 15px;
}

/* .create_coupan{
    border: none;
    outline: none;
    background-color: #398db4;
    padding: 8px 20px;
    font-size: 14px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    transition: 0.6s ease-in-out;
    -webkit-transition: 0.6s ease-in-out;
    -moz-transition: 0.6s ease-in-out;
    -ms-transition: 0.6s ease-in-out;
    -o-transition: 0.6s ease-in-out;
} */

/* .create_coupan:hover{
    background-color: rgb(43, 86, 122);
    color: #fff;
} */

.create_coupan {
    z-index: 1;
    position: relative;
    font-size: inherit;
    font-family: inherit;
    font-size: 14px;
    color: white;
    padding: 0.7em 1.4em;
    outline: none;
    border: none;
    border-radius: 100px;
    background-color: hsl(236, 32%, 26%);
    overflow: hidden;
    transition: color 0.4s ease-in-out;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
  
  .create_coupan::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    color: #fff;
    background-color: rgb(79, 129, 170);
    transform-origin: center;
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
    transition: transform 0.45s ease-in-out;
  }
  
  .create_coupan:hover {
    cursor: pointer;
    color: #ffffff;
  }
  
  .create_coupan:hover::before {
    transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
  }





.coupan_heading{
    font-size: 20px;
    border-bottom: 1px solid #e1e1e1;
}
.coupan_heading h1{
    padding-top: 10px;
    font-size: 18px;
    padding-left: 20px;
}

.coupan_sec{
    height: 150px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-clip: border-box;
    background-image: url('./coupon.png');
}

.coupan_sec h3{
    font-size: 20px;
    transform: rotate(-90deg);
    top: 65px;
    color: #fff;
    right: -8px;
    font-weight: 500;
    position: absolute;
    margin-left: 40px!important;
}

.active_coupon{
    padding-top: 20px;
    padding-left: 30px;
    color: #41ce00!important;
    font-size: 14px;
    font-style: normal;
    font-family: Roboto;
}

.amount_coupon{
    padding-top: 20px;
    padding-left: 30px;
    font-weight: 400;
    font-size: 26px;
    color: #e2e2e1;
}

.limit_coupon{
    font-size: 14px;
    padding-left: 30px;
    color: #e2e2e1;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.coupan_sec .mdi-information-outline{
    padding-left: 20px;
}

.couponedit button{
    padding: 6px 12px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 2px;
    background-color: #448825;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.coupondelete button{
    padding: 6px 12px;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 2px;
    background-color: #fd3f3f;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.Save_changes{
    border: none;
    outline: none;
    background-color: #256488;
    padding: 8px 20px;
    color: #fff;
    font-size: 14px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.couponActive{
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
}
.coupondeactive{
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
}


@media screen and (max-width: 600px) {
    .coupan_sec h3{
        font-size: 18px;
        top: 50px;
        right: -15px;
        font-weight: 400;
        position: absolute;
    }
    .active_coupon{
        padding-top: 15px;
        padding-left: 20px;
    }
    .amount_coupon{
        padding-top: 10px;
        padding-left: 20px;
        font-weight: 400;
        font-size: 23px;
        color: #e2e2e1;
    }
}

@media screen and (max-width: 768px) {
    .coupan_sec h3{
        font-size: 18px;
        top: 54px;
        right: -15px;
        font-weight: 400;
        position: absolute;
    }
    .amount_coupon{
        padding-top: 12px;
        padding-left: 40px;
        font-weight: 400;
        font-size: 23px;
        color: #e2e2e1;
    }
}

@media screen and (max-width: 1024px) {
    .coupan_sec h3{
        font-size: 18px;
        top: 65px;
        right: -10px;
        font-weight: 400;
        position: absolute;
    }
    .amount_coupon{
        padding-top: 7px;
        padding-left: 20px;
        font-weight: 400;
        font-size: 23px;
        color: #e2e2e1;
    }
}

#couponAddAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
#couponDeleteAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
#couponDeleteAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
#couponUpdateAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}