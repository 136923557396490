.invoice_card{
    position: relative;
}
.invoice_card h5{
    position: absolute;
    top: -40px;
    left: 0px;
    padding: 10px 0px;
    width: 100%;
    font-size: 15px!important;
    color: #fff;
    background-color: red;
}

.invoice_card i{
    font-size: 22px;
}

.Today{
    background-color: #efa63f!important;
}

.Weekly{
    background-color: #5eb562!important;
}

.Monthly{
    background-color: #e73e3a!important;
}


.yearly{
    background-color: #5ab6ca!important;
}

.sactive{
    background-color: rgb(68, 177, 18);
    padding: 3px 20px;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.deactive{
    background-color:red;
    text-align: center;
    padding: 3px 20px;
    color: #fff;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}



@media (max-width: 600px){
    .invoice_card{
        margin-top: 40px!important;
    }
}