.company_logo img{
    height: 150px;
    margin-left: auto;
}
.company_name{
    border-bottom: 1px solid #e9e9e9;
}
.company_detail p{
    font-size: 15px;
    font-weight: 400;
    display: inline;
}
.company_detail span{
    font-size: 16px;
    display: inline;   
}
.company_name:hover{
    background-color: #f0f0f0;
}