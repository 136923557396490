.table {
    width: 100%;
    margin-bottom: 1.5rem;
    color: #212529;
    margin-top: 10px;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
.table thead th {
    vertical-align: bottom;
    padding: 15px;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
    padding: 15px;
}




.shortview{
    width: 33%;
    text-align: center;
    float: left;
}
.shortview i{
    padding: 2px 5px;
    color: #fff;
    background-color: royalblue;
    border-radius: 3px;
    font-size: 18px;
}

.shortchat{
    width: 33%;
    text-align: center;
    float: left;
}
.shortchat i{
    padding: 2px 5px;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    background-color: rgb(1, 138, 8);
}

.shortdelete{
    width: 33%;
    text-align: center;
    float: left;
}
.shortdelete i{
    padding: 2px 5px;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    background-color: rgb(255, 0, 0);
}














@media only screen and (max-width: 600px) {
    .shortview{
        text-align: center;
        float: left;
    }
    .shortchat{
        text-align: center;
        float: left;
    }
    .shortdelete{
        text-align: center;
        float: left;
    }
    .shortview i{
        padding: 1px 2px;
        color: #fff;
        font-size: 15px;
        background-color: royalblue;
        border-radius: 3px;
    }
    .shortchat i{
        padding: 1px 2px;
        color: #fff;
        font-size: 15px;
        border-radius: 3px;
        background-color: rgb(255, 0, 0);
    }
    .shortdelete i{
        padding: 1px 2px;
        color: #fff;
        font-size: 15px;
        border-radius: 3px;
        background-color: rgb(1, 138, 8);
    }
}

.infoButton1{
    margin: 0!important;
    padding: 0!important;
    outline: none!important;
    border: none!important;
}
.infoButton2{
    color: #fff;
    outline: none!important;
    padding: 7px 18px;
    border: none!important;
}