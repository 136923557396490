.table {
    width: 100%;
    margin-bottom: 1.5rem;
    color: #212529;
    margin-top: 10px;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
.table thead th {
    vertical-align: bottom;
    padding: 15px;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
    padding: 15px;
}




.shortview{
    width: 50%;
    text-align: center;
    float: left;
}
.shortview i{
    padding: 2px 5px;
    color: #fff;
    background-color: royalblue;
    border-radius: 3px;
    font-size: 18px;
}

/* .shortchat{
    width: 33%;
    text-align: center;
    float: left;
}
.shortchat i{
    padding: 2px 5px;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    background-color: rgb(1, 138, 8);
} */

.shortdelete{
    width: 50%;
    text-align: center;
    float: left;
}
.shortdelete i{
    padding: 2px 5px;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    background-color: rgb(255, 0, 0);
}


/* ########## company_profile ########### */

.company_profile{
    border: 1px solid #e5e5e5;
}
.company_profile_title{
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
}

.company_search input{
    height: 35px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
    border: 1px solid #e1e1e1;
}
.company_search input:focus{
    transition: 2s all;
    border: 1px solid #0b5299;
    -webkit-transition: 2s all;
    -moz-transition: 2s all;
    -ms-transition: 2s all;
    -o-transition: 2s all;
}

.srch_btn{
    color: #fff;
    padding: 5px 30px;
    border: none;
    font-size: 15px;
    font-weight: 500;
    font-family: Roboto;
    outline: none;
    background-color: #09539e;
}


.logo_sec{
    padding: 30px;
}
.company_logo{
    height: 100%;
    width: 100%;
}
#companyLogo img{
    width: 300px;
    height: auto;
}

.company_details h4{
    font-size: 23px;
    font-weight: 550;
    font-family: lato;
}

.hr{
    margin-top: 10px;
    height: 8px;
}

.company_details p{
    font-size: 16px;
    font-family: Roboto;
}
.company_details i{
    font-size: 16px;
}

.company_contact p{
    line-height: 15px;
}
.company_contact i{
    font-size: 18px;
    color: rgb(247, 92, 92);;
    display: inline;
}
.company_contact span{
    display: inline;
    font-size: 14px;
    color: #464444;
}

.com_address i{
    color: rgb(247, 92, 92);
    font-size: 25px;
}
.com_address span{
    font-size: 15px;
    font-weight: 500;
}

.removeColor{
    text-decoration: none;
    color: #212529;
}

.mobile_search{
    display: none;
}










@media only screen and (max-width: 600px) {
    .shortview{
        text-align: center;
        float: left;
    }
    /* .shortchat{
        text-align: center;
        float: left;
    } */
    .shortdelete{
        text-align: center;
        float: left;
    }
    .shortview i{
        padding: 1px 2px;
        color: #fff;
        font-size: 15px;
        background-color: royalblue;
        border-radius: 3px;
    }
    /* .shortchat i{
        padding: 1px 2px;
        color: #fff;
        font-size: 15px;
        border-radius: 3px;
        background-color: rgb(255, 0, 0);
    } */
    .shortdelete i{
        padding: 1px 2px;
        color: #fff;
        font-size: 15px;
        border-radius: 3px;
        background-color: rgb(255, 0, 0);
    }
    .search_bar{
        display: none;
    }
    .mobile_search{
        display: block;
    }
}

#messageAlertCompany{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}