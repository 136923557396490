.email_broadcast input{
    width: 100%;
    height: 40px;
    border: 1px solid #e1e1e1;
    outline: none;
    font-size: 14px;
}
.email_broadcast textarea{
    width: 100%;
    border: 1px solid #e1e1e1;
    outline: none;
    font-size: 14px;
}

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.waves-effect .image-icon{
   height: 20px;
   width: 20px;
   margin-top: 3px;
   margin-left: 10px;

}

.waves-effect input[type=file] {
    display: none!important;
}


.waves-effect input[type="file" i] {
    appearance: none;
    background-color: initial;
    cursor: default;
    align-items: baseline;
    color: inherit;
    text-overflow: ellipsis;
    white-space: pre;
    text-align: start !important;
    padding: initial;
    border: initial;
    overflow: hidden !important;
}


.send_email{
    border: none;
    outline: none;
    background-color: #16477e;
    color: #fff;
    font-size: 14px;
    padding: 7px 15px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    transition: 1s all;
    -webkit-transition: 1s all;
    -moz-transition: 1s all;
    -ms-transition: 1s all;
    -o-transition: 1s all;
}

.send_email:hover{
    background-color: rgb(35, 115, 126);
    padding: 7px 25px;
}

.send_email {
    z-index: 1;
    color: white;
    outline: none;
    border: none;
  }
  
  /* .send_email:hover {
    cursor: pointer;
    animation: jelly 0.5s;
  }
  
  @keyframes jelly {
    0%,
    100% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  } */


  .email_broadcast_header{
      background-color: #57adf3;
      font-size: 15px;
      color: #fff;
      font-weight: 400;
      letter-spacing: 1px;
  }
  .email_broadcast_header i{
      font-size: 18px;
      color: #fff;
      padding: 0px 6px;
  }

  .email_broadcast_input input{
      width: 100%;
      height: 40px;
      border: none;
      outline: none;
      border-bottom: 1px solid #e1e1e1;
  }
  
  .email_broadcast_input textarea{
        margin-top: 6px;
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
        border-bottom: 1px solid #e1e1e1;
    }

.datalist{
  border: 1px solid grey!important;
  padding: 0 10px;
}

#mailAddAlert{
  position: fixed;
  top: 30px;
  right: 5px;
  display: none;
  z-index: 4444;
}