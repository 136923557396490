.remove{
    background-color: red;
    border: none;
    outline: none;
    padding: 5px 10px;
    color: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.back_btn{
    background-color: rgb(15, 65, 158);
    color: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 5px 20px;
}