.admin__profile{
    border: 1px solid #e5e5e5;
}
.admin__profile_title{
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-group {
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
}
.admin_form label {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #222;
}

.material-icons-name {
    font-size: 18px;
    color: rgb(96, 143, 243);
}

.zmdi {
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.admin_form input {
    width: 100%;
    display: block;
    border: none;
    border-bottom: 1px solid #999;
    padding: 6px 30px;
    font-family: Poppins;
    box-sizing: border-box;
}
.admin_form .mdi-account{
    font-size: 20px;
}
.admin_form .mdi-email-variant{
    font-size: 18px;
}
.admin_form .mdi-lock {
    font-size: 18px;
}
.admin_form .mdi-phone-plus{
    font-size: 18px;
}

.admin_form .login_button{
    height: 40px;
    width: 100px;
    background-color: #6dabe4;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 3px;
    border: none;
    outline: none;
} 
.admin_form .reset_button{
    height: 40px;
    width: 100px;
    background-color: #e46d6d;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 3px;
    border: none;
    outline: none;
    margin-left: 20px;
} 




/* ########### admin view css ########### */

.table {
    width: 100%;
    margin-bottom: 1.5rem;
    color: #212529;
    margin-top: 10px;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
.table thead th {
    vertical-align: bottom;
    padding: 15px;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
    padding: 15px;
}



 .adminview{
    width: 33%;
    text-align: center;
    float: left;
}
.adminview button{
    padding: 0!important;
    margin: 0!important;
    outline: none!important;
    border: none!important;
    color: #fff;
    background-color: royalblue;
    border-radius: 3px;
    font-size: 18px;
}

.adminedit{
    width: 33%;
    text-align: center;
    float: left;
}
.adminedit button{
    padding: 0!important;
    margin: 0!important;
    outline: none!important;
    border: none!important;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    background-color: rgb(1, 138, 8);
}

.admindelete{
    width: 33%;
    text-align: center;
    float: left;
}
.admindelete button{
    padding: 0!important;
    margin: 0!important;
    outline: none!important;
    border: none!important;
    color: #fff;
    font-size: 18px;
    border-radius: 3px;
    background-color: rgb(255, 0, 0);
}

.mobile_search{
    display: none;
}




@media only screen and (max-width: 600px) {
    .adminview{
        text-align: center;
        float: left;
    }
    .adminedit{
        text-align: center;
        float: left;
    }
    .shortdelete{
        text-align: center;
        float: left;
    }

    .admin_form .reset_button{
        margin-left: 5px;
    }
    .search_bar{
        display: none;
    }
    .mobile_search{
        display: block;
    }

}


.error{
    color: red;
    font-size: 14px;
    margin-top: -15px!important;
}

#messageAddAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
#messageDeleteAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
.messageDanger{
    color: white;
    padding: 10px 5px;
    background-color: red;
}
.messageSuccess{
    color: white;
    padding: 10px 5px;
    background-color: rgba(0, 128, 0, 0.7);
}
