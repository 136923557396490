.profile{
    font-size: 14px;
    padding: 4px 14px;
    background-color: rgb(9, 145, 9);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 10px;
}
.Education{
    font-size: 14px;
    padding: 4px 10px;
    background-color: rgb(9, 145, 9);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 10px;
}
.Exprience{
    font-size: 14px;
    padding: 4px 10px;
    background-color: rgb(9, 145, 9);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 10px;
}



.User_profile{
    border: 1px solid #e5e5e5;
}
.User_profile_title{
    font-size: 16px;
    font-weight: 500;
    padding: 12px;
    border-bottom: 1px solid #e5e5e5;
}

.profile__image{
    height: 200px;
    width: 200px;
    border-radius: 5px;
    margin: auto;
}


.hr{
    margin-top: 10px;
    height: 8px;
}

.address p{
    line-height: 15px;
}
.address i{
    font-size: 18px;
    color: #3252f0;
    display: inline;
}
.address span{
    display: inline;
    font-size: 14px;
    color: #464444;
}

.skill h5{
    font-size: 16px;
    font-weight: 600;
    color: #ff5f5f;
}

.skill li{
    text-decoration: none;
    list-style: none;
    padding-left: 40px;
    font-size: 14px;
    font-weight: 450;
    font-family: Roboto;
}
.domain a{
    text-decoration: none;
    font-family: roboto;
    font-size: 14px;
    color: #4b87c8;
}
.domain i{
    color: #4b87c8;
    font-size: 18px;
}


/* ######## user_Qualification ######## */
.user_Qualification ul{
    margin-left: -49px;
  
}
.user_Qualification li{
    text-decoration: none;
    list-style: none;
    display: inline;
    margin-left: 20px;
    font-size: 15px;
    font-weight: 430;
    cursor: pointer;
  
}
.user_Qualification li:hover{
  border-bottom: 2px solid #ff5f5f;
  padding-bottom: 20px;
}
#edu{
    color: #ff5f5f;
}





.certificate img{
    height: 110px;
    width: 100%;
}

.education p{
    font-size: 15px;
    font-weight: 500;
   
}
.education h6{
    font-size: 14px;
    color: #616161;
}




.user_details h4{
    font-size: 23px;
    font-weight: 600;
}
.user_details .post{
    font-size: 15px;
    color: #929292;
}
.user_details .avaliable{
    font-size: 15px;
    color: rgb(252, 102, 102);
}
.user_details .discription span{
    font-size: 14px;
    font-family: Roboto;
}