
.table {
    width: 100%;
    margin-bottom: 1.5rem;
    color: #212529;
    margin-top: 10px;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
}
.table thead th {
    vertical-align: bottom;
    padding: 15px;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
.table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
    padding: 15px;
}



 .reqview{
    width: 50%;
    text-align: center;
    float: left;
}
.reqview button{
    margin: 0!important;
    padding: 0!important;
    outline: none!important;
    border: none!important;
    /*padding: 2px 5px;*/
    color: #fff;
    background-color: royalblue;
    border-radius: 3px;
    font-size: 18px;
}

#buttonPadding{
    padding: 5px 8px!important;
}


/*  ###########  view requested project css  ############ */

.view_project{
    border: 1px solid #e5e5e5;
}
.view_project h6{
    font-size: 15px;
    font-weight: 500;

}
.view_project .close_button i{
    float: right;
    margin-top: 10px;
    background-color: red;
    padding: 2px 6px;
    border-radius: 3px;
    color: #fff;
    margin-bottom: 5px;
}

.req_project_detail label{
    font-size: 14px;
}
.req_project_detail input{
    font-size: 14px;
    border: none;
    margin: 0px!important;
}
.req_project_detail .form-group{
    margin: 0px!important;
    border-bottom: 1px dashed #e5e5e5;
}
.req_project_detail textarea{
    font-size: 14px;
}


.mobile_search{
    display: none;
}









@media only screen and (max-width: 600px) {
    .reqview{
        text-align: center;
        float: left;
    }
    .reqdelete{
        text-align: center;
        float: left;
    }
    .search_bar{
        display: none;
    }    
    .mobile_search{
        display: block;
    }
}


#messageAlertLead{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}