.view_profile{
    border: 1px solid #e5e5e5;
}
.view_profile h6{
    font-size: 16px;
    font-weight: 500;

}
.view_profile .close_button i{
    float: right;
    margin-top: 5px;
    background-color: red;
    padding: 2px 6px;
    border-radius: 3px;
    color: #fff;
    margin-bottom: 5px;
}