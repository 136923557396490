.Edit_profile{
    border: 1px solid #e5e5e5;
}
.Edit_profile h6{
    font-size: 16px;
    font-weight: 500;

}
.Edit_profile .close_button i{
    float: right;
    margin-top: 5px;
    background-color: red;
    padding: 2px 6px;
    border-radius: 3px;
    color: #fff;
    margin-bottom: 5px;
}


.Edit_profile .login_button{
    height: 40px;
    width: 160px;
    background-color: #6dabe4;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    border: none;
    outline: none;
} 
.Edit_profile .reset_button{
    height: 40px;
    width: 100px;
    background-color: #e46d6d;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-left: 20px;
} 
#messageUpdateNotificationAlert{
    position: fixed;
    top: 30px;
    right: 5px;
    display: none;
    z-index: 4444;
}
